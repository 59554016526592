<template>
  <v-card class="app-card" color="#fff" elevation="0" tile>
    <v-row class="ma-0 flex-nowrap">
      <section>
        <div class="app-card__btn-move handler" v-if="mode !== 'low'">
          <div class="app-card__checkbox" v-if="mode !== 'low'">
            <v-checkbox
              v-model="checkID"
              @change="$emit('addRemoveID', item.id, listItems)"
              :value="item.id"
              :key="checkID"
            >
            </v-checkbox>
          </div>
        </div>
      </section>

      <section class="_w-100">
        <v-row class="flex-nowrap mx-0 mb-1">
          <div class="pa-2 pt-4">
            <div class="app-card__img-wrapper mt-1">
              <img :src="item.image" class="app-card__image" alt="" />
            </div>
          </div>

          <div class="app-card__content py-4">
            <div
              class="app-card__header d-flex"
              :class="title ? 'justify-space-between' : 'justify-end'"
            >
              <h3 v-if="title" class="caption-3 mr-2">
                <span v-if="item.brandName"> {{ item.brandName }}<br /> </span
                >{{ title }} 
              </h3>
            </div>
          </div>

          <div v-if="mode !== 'low' && !!!listID.length" class="py-4">
            <v-menu
              bottom
              left
              transition="slide-y-transition"
              width="250"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <ItemCtrlMenuMobile
                  :isValid="isValid"
                  :item="item"
                  @open="openConfirm"
                />
              </v-list>
            </v-menu>
          </div>
        </v-row>

        <div class="mx-2">
          <div class="app-card__body mb-1 mt-1">
            <p
              v-if="
                (item.content_type === 'product' ||
                  item.content_type === 'carousel') &&
                item.article
              "
              class="mb-0 txt"
            >
              Артикул {{ item.article }}
            </p>

            <div v-else class="colors d-flex flex-nowrap">
              <div class="d-flex flex-nowrap align-center">
                <v-icon class="mr-2" v-if="!item.background">
                  mdi-cancel
                </v-icon>
                <div
                  v-else
                  class="circle mr-2"
                  :style="`background-color: ${item.background}`"
                ></div>

                <p class="mb-0 txt mr-4">Цвет фона</p>
              </div>

              <div class="d-flex flex-nowrap align-center">
                <div
                  class="circle mr-2"
                  :style="`background-color: ${item.color}`"
                ></div>

                <p class="mb-0 txt mr-4">Цвет текста</p>
              </div>
            </div>
          </div>

          <div
            v-if="item.available_for && item.available_for.length"
            class="app-card__groups d-flex"
          >
            <p
              v-for="(el, i) in item.available_for"
              :key="i"
              class="mb-0 txt mr-2"
            >
              {{ el.name }}{{ item.available_for[i + 1] ? "," : "" }}
            </p>
          </div>
        </div>

        <div
          class="
            app-card__footer
            mt-auto
            d-flex
            justify-space-between
            align-end
            flex-nowrap
            mb-2
            mx-2
          "
        >
          <div class="date-range">
            <h4 class="caption-4">
              <span v-if="item.start_date">
                {{ $moment(item.start_date).format("DD.MM.YYYY") }}
              </span>
              <span v-if="item.end_date">
                - {{ $moment(item.end_date).format("DD.MM.YYYY") }}
              </span>
            </h4>
          </div>

          <div
            v-if="mode !== 'low' && status && Object.keys(status).length"
            class="status"
          >
            <v-icon :color="status.color">
              {{ status.icon }}
            </v-icon>
          </div>
        </div>
      </section>
    </v-row>

    <div
      v-if="isOpenConfirm"
      class="overlay overlay_without-background"
      @click.stop="closeConfirm($event)"
    >
      <transition name="fade">
        <Confirm
          :items="[item]"
          :question="'Вы действительно хотите удалить этот элемен?'"
          @agree="delItem"
          @close="isOpenConfirm = false"
        />
      </transition>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

import Confirm from "@/components/models/Confirm";
import ItemCtrlMenuMobile from "@/components/promo/ItemCtrlMenuMobile";

export default {
  name: "PromoListItem",
  props: {
    listItems: {
      type: Array,
    },
    item: {
      type: Object,
      require: true,
    },
    listID: {
      type: Array,
    },
    mode: {
      type: String,
    },
  },
  components: { ItemCtrlMenuMobile, Confirm },
  data: (vm) => ({
    checkID: vm.listID && vm.listID.includes(vm.item.id) ? vm.item.id : null,
    isOpenConfirm: false,
  }),
  computed: {
    isValid: function () {
      if (this.$route.name === "PromoCarousel") return true;
      if (this.item.translates.length && this.item.available_for.length) {
        return true;
      } else {
        return false;
      }
    },
    title: function () {
      if (this.$route.name === "PromoCarousel") return this.item.name;
      if (this.item.translates.length) {
        return this.item.translates.find((obj) => obj.language.code === "ru")
          .name;
      } else if (this.item.name) {
        return this.item.name;
      }
    },
    status: function () {
      switch (true) {
        case !this.isValid:
          return {
            icon: "mdi-alert-circle-outline",
            color: "#EB174B",
            name: "not-valid",
            txt: "Не валидное",
          };
        case this.item.is_public:
          return {
            icon: "mdi-check-circle-outline",
            color: "#27A248",
            name: "prod",
            txt: "Опубликовано",
          };
        case this.item.is_test && !this.item.is_public:
          return {
            icon: "mdi-dots-horizontal-circle-outline",
            color: "#818181",
            name: "test",
            txt: "Тестируется",
          };
        default:
          return {};
      }
    },
  },
  methods: {
    ...mapActions(["deletePromo", "deleteProductFromCarousel"]),
    closeConfirm(ev) {
      const target = ev.target;
      const isModal = target.closest(".confirm");
      if (isModal) return;
      this.isOpenConfirm = false;
    },
    openConfirm() {
      this.isOpenConfirm = true;
    },
    async delItem(arr) {
      const ids = JSON.stringify(arr);
      if (this.item.content_type !== "carousel") {
        await this.deletePromo(ids);
      } else {
        await this.deleteProductFromCarousel(ids);
      }
    },
  },
  watch: {
    listID() {
      this.checkID = this.listID.includes(this.item.id) ? this.item.id : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-card {
  &__btn-move {
    border-right: 1px solid #dadada;
    background-image: url("~@/assets/img/move-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    min-width: 30px;
    height: 100%;
  }

  &__checkbox {
    display: grid;
    place-items: center;
  }

  &__img-wrapper {
    position: relative;
    border-radius: 3px;
    height: 75px;
    overflow: hidden;
  }

  &__image {
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  &__content {
    width: 100%;
  }
}

.caption-3 {
  display: -webkit-box;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #56565a;
}
.caption-4 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #56565a;
}
.txt {
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 19px;
  color: #56565a;
}
.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #dadada;
}

.tooltip__txt {
  color: #56565a;
}

._w-100 {
  width: 100%;
}
.overlay {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
</style>

<style lang="scss">
.app-card__checkbox {
  .v-input--selection-controls__input {
    margin-right: 0;
  }
}
</style>
